@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Outfit;
}

@font-face {
  font-family: 'Outfit';
  src: url('./fonts/Outfit-Regular.ttf');
}
